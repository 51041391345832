<script setup lang="ts">
import Check from '~/assets/svg/check.svg?component';

const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const features = computed(() => {
    const list = props.blok.features.split('\n');
    return list;
});
</script>

<template>
    <div>
        <div class="bg-white rounded-lg drop-shadow-xl dark:bg-space-blue">
            <div
                class="bg-no-repeat bg-cover bg-center rounded-lg bg-blend-multiply h-60 relative"
                :style="`background-image: url('${blok.image.filename}/m/0x480')`"
            >
                <div
                    class="rounded-lg bg-gradient-to-t from-[rgba(0,0,0,0.5)] bg-blend-multiply h-60"
                >
                    <div class="absolute bottom-0 p-5">
                        <TypeLabel :type="blok.type" :title="blok.title || ''" />
                    </div>
                </div>
            </div>
        </div>
        <ul role="list" class="my-6 px-2 lg:mb-0 space-y-4 text-gray-400">
            <li v-for="feature in features" class="flex space-x-2.5">
                <div>
                    <Check />
                </div>
                <span class="leading-tight text-gray-500 dark:text-gray-400">{{ feature }}</span>
            </li>
        </ul>
        <BlokLink
            v-if="blok.linkText && blok.link"
            :to="blok.link"
            class="inline-flex items-center mb-12 mx-2 md:mt-6 text-lg font-medium text-primary-600 hover:text-primary-800 dark:text-primary-500 dark:hover:text-primary-700"
        >
            {{ blok.linkText }}
            <svg
                class="ml-1 w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                ></path>
            </svg>
        </BlokLink>
    </div>
</template>
