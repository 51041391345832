<script setup>
const storyblokApi = useStoryblokApi();
const { locale } = useI18n();

import giveAwayIcon from '~/assets/svg/give-away.svg?url';
import buyForMeIcon from '~/assets/svg/buy-for-me.svg?url';
import MoveDeliverIcon from '~/assets/svg/move-deliver.svg?url';
import recyclingIcon from '~/assets/svg/recycling.svg?url';

const props = defineProps({
    type: {
        type: String,
        default: () => '',
    },
    title: {
        type: String,
        default: () => '',
    },
});

const typeGradient = computed(() => {
    switch (props.type) {
        case 'h2t':
            return 'from-blue-gradient-1 to-blue-gradient-2';
        case 'b4m':
            return 'from-purple-pink-gradient-1 to-purple-pink-gradient-2';
        case 'giaw':
            return 'from-yellow-pink-gradient-1 to-yellow-pink-gradient-2';
        case 'paaw':
            return 'from-green-gradient-1 to-green-gradient-2';
        default:
            return '';
    }
});

const typeIcon = computed(() => {
    switch (props.type) {
        case 'h2t':
            return MoveDeliverIcon;
        case 'b4m':
            return buyForMeIcon;
        case 'giaw':
            return giveAwayIcon;
        case 'paaw':
            return recyclingIcon;
        default:
            return '';
    }
});
</script>

<template>
    <div class="flex" v-if="type">
        <div
            :class="`flex my-auto justify-center items-center w-8 h-8 bg-gradient-to-br rounded-lg ${typeGradient}`"
        >
            <img class="h-5" :src="typeIcon" :alt="`${props.type} icon`"/>
        </div>
        <h3
            class="ml-2 my-auto justify-center text-xl font-serif font-bold leading-tight text-white"
        >
            {{ title }}
        </h3>
    </div>
</template>
